import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import Layout from '../components/layout/layout'
import { SearchBox } from '../components/search-box/search-box';
import { Button } from '../components/button/button';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    max-width: 500px; // This feels like a hacky to achieve single centered grid item.
    margin: 0 auto;
    padding: 1rem;
`

const CardWrap = styled.div`
    margin-bottom: 1rem;
    border-radius: 1rem;
    transition: 0.25s;
    padding: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`

const ProductInfo = styled.span`
  display: inline-block;
  font-size: 1rem;
`

const CardTitle = styled.h3`
    font-size: 1.5rem;
    margin: 0;
`

const Status = styled.span`
  display: inline-block;
  color: white;
  background-color: ${({ theme }) => theme.colors.muted};
  padding: 0.5rem;
  border: 1px solid transparent;
  border-radius: 8px;

`

const CardText = styled.p`
    font-size: 1rem;
    line-height: 1.25;
`

const title = "Calorie Planning for Fat Loss"
const description = "Learn how to calculate the exact amount of calories your body needs to lose fat fast! This course includes understanding your body composition, figuring out calorie & macronutrient intake, and how to track progress while avoiding burnout. With a science-based calorie plan in place - the body of your dreams will become a reality. Let's get started!"  



// ConverKit Form

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
`

const Text = styled.p`
  margin: 1rem 0;
  @media screen and (max-width: 1100px) {
    text-align: center;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 300px; */
`

const EmailInput = styled(SearchBox)`
  margin: 0 0 1rem;
  /* width: 89%; */
`

const ProductsPage = () => {
  
  const imgStyle = {
    borderRadius: "1rem"
  }

  return (
    <Layout pageTitle='Courses' showNav={true}>
      <h1>Online Courses</h1>

      <Grid>
        <CardWrap>
            {/* https://unsplash.com/photos/ZSpkUThb7qk */}
            <StaticImage 
              src='../../static/img/fork-wrapped-with-tape-measure.jpg' 
              alt="fork wrapped with tape measure" 
              style={imgStyle} 
              imgStyle={imgStyle} 
            /> 
            <Header>
              <ProductInfo>Course • 13 Lessons</ProductInfo>
              <Status>In Development</Status>
            </Header>
            <CardTitle>{title}</CardTitle>
            <CardText>{description}</CardText>
        </CardWrap>
      </Grid>

      <FormContainer>
        <h2>Interested?</h2>
        <Text>We'll send you a special offer when our first course is ready.</Text>
        <Form method="post" action="https://app.convertkit.com/forms/3528853/subscriptions" data-sv-form="3528853" data-uid="87a4bb3e22">
          <EmailInput type="email" name="email_address" aria-label="Email Address" placeholder="Email Address" required />
          <Button primary>Let Me Know</Button>
        </Form>
      </FormContainer>
      

    </Layout>
  )
}

export default ProductsPage;